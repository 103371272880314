<template>
	<v-sheet v-if="incentiveOnly">
		<navigation :id="id"></navigation>
		<project-intro
			:id="project.id"
			intro="This area can be used to add individual incentive records"
		></project-intro>
		<v-card flat color="lightest">
			<v-card-text>
				<p>Please provide details of your payee below.</p>
				<v-form ref="form" v-model="valid">
					<v-text-field
						solo
						flat
						:rules="notEmpty"
						label="Statement of Work Reference Number"
						v-model="sow"
					></v-text-field>
					<v-text-field
						solo
						flat
						label="Email"
						prepend-icon="mdi-at"
						:rules="emailRules"
						v-model="email"
					>
					</v-text-field>
					<v-row>
						<v-col>
							<v-text-field
								solo
								flat
								label="First Name"
								:rules="notEmpty"
								v-model="first_name"
							>
							</v-text-field>
						</v-col>
						<v-col>
							<v-text-field
								solo
								flat
								label="Last Name"
								:rules="notEmpty"
								v-model="last_name"
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-text-field
						solo
						flat
						label="Incentive Amount"
						:rules="notZero"
						prepend-icon="mdi-currency-gbp"
						type="number"
						v-model="amount"
					>
					</v-text-field>
					<v-checkbox
						label="Participant's research activity is complete"
						required
						:rules="notEmpty"
					></v-checkbox>
					<v-checkbox
						label="Participant has provided consent for their information to be share with Meisterworks"
						required
						:rules="notEmpty"
					></v-checkbox>
					<v-checkbox
						label="Participant has confirmed their agreement to receive their incentive as an Amazon voucher to the email address I am providing"
						v-model="amazon"
					></v-checkbox>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn text @click="clear">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="submit" :disabled="!valid">
					Submit
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-sheet>
</template>

<script>
	import ProjectIntro from "@c/project/ProjectIntro";
	import Navigation from "@c/incentives/Navigation";
	export default {
		name: "AddRecord",
		props: {
			id: {type: String}
		},
		components: {
			Navigation,
			ProjectIntro
		},
		data: () => {
			return {
				amount: 0,
				amazon: false,
				email: null,
				sow: null,
				last_name: null,
				first_name: null,
				valid: null,
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
				],
				notEmpty: [(v) => !!v || "Value is required"],
				notZero: [(v) => v > 0 || "Amount must be greater than zero"]
			};
		},
		computed: {
			project() {
				return this.$store.state.projects.data[this.id];
			},
			incentiveOnly() {
				return this.project && this.project.incentive_only;
			}
		},
		methods: {
			clear() {
				this.$refs.form.reset();
			},
			submit() {
				const self = this;
				let item = {
					status: "new",
					project: self.id,
					sow: self.sow,
					email: self.email,
					first_name: self.first_name,
					last_name: self.last_name,
					amount: self.amount,
					amazon: self.amazon
				};
				self.$store
					.dispatch("incentiveSubmissions/insert", item)
					.then(() => {
						self.$root.$emit(
							"showToast",
							"Submission complete - please check the View page for details of submitted participants"
						);
						self.clear();
					})
					.catch((e) => {
						console.log(e);
						self.$root.$emit("showToast", "Error Saving", true);
					});
			}
		},
		created() {
			this.$store.dispatch("projects/fetchById", this.id);
		}
	};
</script>
