<template>
	<secondary-navigation>
        <template v-slot:prepend>
            <nav-item :mini="mini" :link="`/projects/${id}/documents`" text="Back to Project" icon="mdi-arrow-left-circle"></nav-item>
            <v-divider></v-divider>
        </template>
        <template v-slot:main>
            <nav-item :mini="mini" :link="`/incentives/${id}/add`" text="Add" icon="mdi-plus-circle"></nav-item>
            <nav-item :mini="mini" :link="`/incentives/${id}/import`" text="Import" icon="mdi-table-arrow-up"></nav-item>
            <nav-item :mini="mini" :link="`/incentives/${id}/view`" text="View" icon="mdi-view-list"></nav-item>
        </template>
	</secondary-navigation>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@c/navigation/Item";
export default {
	name: "IncentivesNavigation",
    props: {
        id: {type: String }
    },
	data() {
		return {
			mini: false,
		};
	},
	computed: {

	},
	watch: {
		
	},
	components: {
		NavItem,
        SecondaryNavigation
	},
};
</script>
